var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"pGGDgTB763KRzlw4OQR3w"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'
import packageJson from './package.json'

const enabled = process.env.NEXT_PUBLIC_SENTRY_ENABLED == 'true'

Sentry.init({
  release: `core@v${packageJson.version}`,
  enabled,
  dsn: 'https://f76137640ddebbc0b2f4eca8fa7f8e2c@o4504499513786368.ingest.sentry.io/4506760049524736',
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
  tracesSampleRate: 1.0,
  profilesSampleRate: 1.0,
  integrations: [new Sentry.BrowserProfilingIntegration()],

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  tracePropagationTargets: [
    /^https:\/\/app.egm-dev\.com/,
    'https://app.egm-dev.com',
    /^https:\/\/app.wealth-eg-dev\.com/,
    'https://app.wealth-eg-dev.com',
  ],
})
